import moment from 'moment';

export const defaultOptions = {
  itemTemplate: null,
  minLen: 2,
  maxLen: 100,
  value: null,
  setLabel: (item) => item,
  items: () => [],
  placeholder: '',
  inputClasses: '',
  wrapperClasses: '',
  inputWrapperClasses: '',
  suggestionListClasses: '',
  suggestionGroupClasses: '',
  suggestionGroupHeaderClasses: '',
  suggestionItemWrapperClasses: '',
  suggestionItemClasses: '',
  type: 'search',
};

export const premiumFeatures = [
  'synthesis',
  'generate',
  'comply',
  'upload',
  'redesign',
  'ag_live_prompts',
];

export const premiumPlans = ['12347', '12349'];

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)}y ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)}m ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)}d ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)}h ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)}m ago`;
  }
  return `${Math.floor(seconds)}s ago`;
}

const getSlideTitleAndID = (item) => {
  const slideTitle = item.title || (item.asset && item.asset.title);
  const slideId = item.unique_id || (item.asset && item.asset.unique_id);

  return {
    slideTitle,
    slideId,
  };
};

export const isEmojiExist = (v) => {
  const regexEmoji =
    /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
  return regexEmoji.test(v);
};

export const isValidEmail = (v) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    v,
  );

/**
 * isOutsideCompanyEmail
 * @param {boolean} isEmailOutsideCompanyEnabled
 * @param {string} userEmail
 * @param {string} enteredEmail
 * @param {string} message
 * @returns {boolean}
 */
export const isOutsideCompanyEmail = (
  isEmailOutsideCompanyEnabled,
  userEmail,
  enteredEmail,
  message,
) => {
  if (isEmailOutsideCompanyEnabled) {
    return true;
  }
  const [, companyDomain] = userEmail.split('@');
  const [, domain] = enteredEmail.split('@');

  if (domain === companyDomain) {
    return true;
  }
  return message;
};

export const downloadFile = (blobData, fileName, extenstion) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const element = document.createElement('a');
  element.href = url;
  element.setAttribute('download', `${fileName}.${extenstion}`);
  document.body.appendChild(element);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const removeSpecialCharacters = (filename) => {
  const parts = filename.split('.');
  const extension = parts.pop();
  const baseFilename = parts.join('.');
  const cleanBaseFilename = baseFilename.replace(/[^\w.-]+/g, '');
  return `${cleanBaseFilename}.${extension}`;
};

export const isFeatureReleaseBadgeEnabled = (
  featureReleaseInfo,
  userCreatedDate,
  module,
  featureId,
  plan,
  userFeatureAccessDate = null,
  additionalFeatureId = null,
) => {
  if (!featureReleaseInfo?.[module] || !plan) {
    return false;
  }
  const featureInfos = featureReleaseInfo[module];
  const feature = featureInfos.find(
    (featureInfo) =>
      featureInfo.feature_id === featureId ||
      featureInfo.feature_id === additionalFeatureId,
  );
  if (feature) {
    const releaseDateString = feature.release_date;
    if (!releaseDateString || feature.enabled === false) {
      return false;
    }
    let duration;
    const currentDate = moment(new Date());
    const releaseDate = moment(releaseDateString, 'YYYY-MM-DD');
    const userAccessDate = moment(userFeatureAccessDate);
    const expiryInDays = feature.badge_expiry_in_days || 28;

    const dynamicModule = [
      'overnight_prezentations',
      'comply',
      'generate',
      'upload',
      'redesign',
    ];
    if (dynamicModule.includes(module)) {
      if (moment(userCreatedDate).isAfter(userAccessDate)) {
        return false;
      }
    }

    if (moment(userCreatedDate).isAfter(releaseDate)) {
      return false;
    }

    if (userAccessDate.isValid() && userAccessDate.isAfter(releaseDate)) {
      duration = moment.duration(currentDate.diff(userAccessDate));
    } else {
      duration = moment.duration(currentDate.diff(releaseDate));
    }
    const isWithinExpiryPeriod =
      duration.asDays() >= 0 && duration.asDays() <= expiryInDays;
    return isWithinExpiryPeriod;
  }
  return false;
};

export const doesPrefsObjectHasImage = (prefs) => {
  if (!prefs) {
    return false;
  }
  const { image_derived: imageDerived, has_image: hasImage } = prefs;
  if (hasImage === 'yes' || hasImage === true) {
    return true;
  }
  if (imageDerived) {
    return imageDerived === '2' || imageDerived === '4';
  }
  return false;
};
export const doesPrefsObjectHasIcon = (prefs) => {
  if (!prefs) {
    return false;
  }
  const { has_icons: hasIcons } = prefs;
  if (hasIcons === 'yes' || hasIcons === true) {
    return true;
  }

  return false;
};

export const getLocalisedNumberInString = (num) => {
  try {
    return Number(num).toLocaleString();
  } catch (e) {
    return num;
  }
};

export const isEmailValid = (val) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(val);
};

export default {
  options: { ...defaultOptions },
  timeSince,
  getSlideTitleAndID,
  isEmojiExist,
  downloadFile,
  isValidEmail,
  getLocalisedNumberInString,
};
